<template>
    <div class="users">
        <form style="margin-bottom:10px;">
            <a-row type="flex" justify="start">
                <a-col :span="10">
                    <a-row :gutter="10">
                        <a-col :span="13">
                            <a-input v-model="keyword" placeholder="请输入关键词" />
                        </a-col>
                        <a-col :span="8">
                            <a-select v-model="check" style="width: 100%" placeholder="请选择审核状态">
                                <a-select-option value="">所有审核状态</a-select-option>
                                <a-select-option :value="0">未提交</a-select-option>
                                <a-select-option :value="2">已提交</a-select-option>
                                <a-select-option :value="1">审核通过</a-select-option>
                                <a-select-option :value="3">审核拒绝</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="3">
                            <a-button type="primary" @click="get_data()">搜索</a-button>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
        </form>
        <a-table :columns="columns" :data-source="tableData" @change="change_filter" :loading="loading" :pagination="page" bordered>
            <template slot="avatar" slot-scope="text, record">
                <img :src="record.avatar" style="max-width:50px;max-height:50px;" />
            </template>
            <template slot="user_type" slot-scope="text, record">
                <div>
                    <a-tag color="pink" v-if="record.is_agent_str">{{record.is_agent_str}}</a-tag>
                    <a-tag color="green" v-if="record.is_sales_str">{{record.is_sales_str}}</a-tag>
                </div>
            </template>
            <template slot="operation" slot-scope="text, record">
                <a @click="()=>{check_data(record)}" v-if="$Func.check_rank('user_check')">审核</a>
            </template>
        </a-table>
        <a-drawer
          title="审核收款帐号"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="check_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="check_model=false"
          v-if="$Func.check_rank('user_check')"
        >
            <a-form-model :model="users_check" :rules="check_rules" layout="vertical" @submit="save_check" ref="check_form">
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="身份证号">
                            <a-input v-model="users_check.account_card_no" placeholder="请输入身份证号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="户名">
                            <a-input v-model="users_check.account_truename" placeholder="请输入户名" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="卡号">
                            <a-input v-model="users_check.account_number" placeholder="请输入卡号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="开户行">
                            <a-input v-model="users_check.account_bank" placeholder="请输入开户行" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="收款帐号审核">
                            <a-radio-group default-value="0" v-model="users_check.account_check" button-style="solid">
                                <a-radio-button :value="0">未提交</a-radio-button>
                                <a-radio-button :value="2">已提交</a-radio-button>
                                <a-radio-button :value="1">审核通过</a-radio-button>
                                <a-radio-button :value="3">审核拒绝</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="check_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>
    </div>
</template>

<script>
    import Func from '../common/func'
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 80,
        },
        {
            title: '姓名',
            dataIndex: 'truename',
            key: 'truename',
            width: 80,
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
            width: 130,
        },
        {
            title: '身份证号',
            dataIndex: 'account_card_no',
            key: 'account_card_no',
            width: 130,
        },
        {
            title: '户名',
            dataIndex: 'account_truename',
            key: 'account_truename',
            width: 90,
        },
        {
            title: '卡号',
            dataIndex: 'account_number',
            key: 'account_number',
            width: 130,
        },
        {
            title: '开户行',
            dataIndex: 'account_bank',
            key: 'account_bank'
        },
        {
            title: '审核',
            dataIndex: 'account_check_str',
            key: 'account_check_str',
            width: 80,
        },
        {
            title: '创建时间',
            dataIndex: 'created',
            key: 'created',
            ellipsis: true,
            width: 180,
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 70,
            key:'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ];
    export default {
        name: 'Account',
        data(){
            return {
                columns,
                form: this.$form.createForm(this),
                keyword:'',
                check:2,
                tableData:[],
                loading:false,
                check_model:false,
                users_check:{
                    id:'',
                    account_card_no:'',
                    account_truename:'',
                    account_number:'',
                    account_bank:'',
                    account_check:'',
                },
                check_rules: {
                    account_card_no:[{ required: true, message: '请输入身份证号', trigger: 'blur' }],
                    account_truename:[{ required: true, message: '请输入户名', trigger: 'blur' }],
                    account_number:[{ required: true, message: '请输入卡号', trigger: 'blur' }],
                    account_bank:[{ required: true, message: '请输入开户行', trigger: 'blur' }],
                    account_check:[{ required: true, message: '请选择收款账号审核状态', trigger: 'blur' }],
                },
                page:{
                    current:1,
                    total:0,
                    pageSize:10
                }
            }
        },
        mounted() {
            if(this.$route.query.check){
                this.check=parseInt(this.$route.query.check);
            }
            
            this.get_data();
        },
        methods: {
            change_filter(pagination){
                this.get_data(pagination.current);
            },
            get_data(page,tip) {
                var keyword = this.keyword;
                if (!page) {
                    page = this.page.current||1;
                }

                if(!tip){
                    this.loading = true
                }

                Func.url_get({
                    action:'users',
                    keyword: keyword,
                    check:this.check,
                    page: page
                },(res) => {
                    this.tableData = res.info.list;
                    var pagination={
                        pageSize:10,
                        total:res.info.foot.total,
                        current:parseInt(res.info.foot.current)
                    }
                    this.page=pagination;
                    this.loading = false
                });
            },

            //审核用户
            check_data(item){
                Func.url_get({
                    action:'users.get_data',
                    id:item.id
                },(res) => {
                    this.users_check = res.info;

                    this.check_model=true;
                });
            },
            save_check(e){
                e.preventDefault();
                this.$refs.check_form.validate(valid => {
                    if (valid) {
                        Func.url_post({
                            action:'users.check_data',
                            ...this.users_check
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.check_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },
            goto_url(url,user_no){
                this.$router.push({
                    path:'/users/'+url,
                    params:{
                        user_no:user_no
                    }
                });
            }
        }
    }
</script>
<style lang="scss">
</style>