<template>
    <div class="admin">
        <form style="margin-bottom:10px;">
            <a-row type="flex" justify="start">
                <a-col :span="17">
                    <a-row :gutter="10">
                        <a-col :span="6">
                            <a-input v-model="keyword" placeholder="请输入关键词" />
                        </a-col>
                        <a-col :span="6">
                            <a-select
                                :value="search_user_value"
                                show-search
                                placeholder="请输入关键词搜索用户"
                                :show-arrow="true"
                                :filter-option="false"
                                :not-found-content="search_fetching ? undefined : null"
                                @search="searchUserSearch"
                                @change="searchUserChange"
                            >
                                <a-select-option key="">所有用户</a-select-option>
                                <a-spin v-if="search_fetching" slot="notFoundContent" size="small" />
                                <a-select-option v-for="d in search_user_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="7">
                            <a-range-picker @change="onChange" />
                        </a-col>
                        <a-col :span="3">
                            <a-button type="primary" @click="get_data()">搜索</a-button>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
        </form>
        <a-table :columns="columns" :data-source="tableData" @change="change_filter" :loading="loading" :pagination="page" bordered>
            <template slot="user" slot-scope="text, record">
                {{record.users_truename+'('+record.users_mobile+')'}}
            </template>
        </a-table>
    </div>
</template>

<script>
    import Func from '../../common/func'
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 80,
        },
        {
            title: '操作日期',
            dataIndex: 'created',
            key: 'created',
            width: 150,
        },
        {
            title: '操作人',
            dataIndex: 'user',
            key: 'user',
            width: 180,
            scopedSlots: { customRender: 'user' },
        },
        {
            title: '操作模块',
            dataIndex: 'opt',
            key: 'opt',
            width: 120,
        },
        {
            title: '操作内容',
            dataIndex: 'content',
            key: 'content',
            ellipsis: true
        }
    ];

    let search_timeout;
    export default {
        name: 'Logs',
        data(){
            return {
                columns,
                keyword:'',
                range:[],
                tableData:[],
                search_fetching: false,
                search_user_value:undefined,
                search_user_list:[],

                loading:false,
                page:{
                    current:1,
                    total:0,
                    pageSize:10
                }
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {
            onChange(date, dateString) {
                this.range=dateString;
            },
            //指定用户 begin
            searchUserSearch(keyword) {
                if (search_timeout) {
                    clearTimeout(search_timeout);
                    search_timeout = null;
                }
                search_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'users.search',
                        keyword
                    },(res) => {
                        this.search_user_list = res.info.list;
                    });
                }, 300);
            },
            searchUserChange(value) {
                this.search_user_value=value;
            },

            change_filter(pagination){
                this.get_data(pagination.current);
            },
            get_data(page,tip) {
                var keyword = this.keyword;
                if (!page) {
                    page = this.page.current||1;
                }

                if(!tip){
                    this.loading = true
                }

                Func.url_get({
                    action:'users_logs',
                    keyword: keyword,
                    range:this.range,
                    uid:this.search_user_value||'',
                    page: page
                },(res) => {
                    this.tableData = res.info.list;
                    var pagination={
                        pageSize:10,
                        total:res.info.foot.total,
                        current:parseInt(res.info.foot.current)
                    }
                    this.page=pagination;
                    this.loading = false
                });
            },
            delete_data(item){
                if(confirm('确定要删除吗')==true){
                    Func.url_get({
                        action:'user_logs.del_data',
                        id:item.id
                    },(res) => {
                        if(res.error===0){
                            this.$message.success(res.info);
                            this.get_data(1,true);
                        }else{
                            this.$message.error(res.info);
                        }
                    });
                }
            },
            goto_url(url,id){
                this.$router.push({
                    path:'/admin/'+url,
                    params:{
                        id:id
                    }
                });
            }
        }
    }
</script>
<style lang="scss">

</style>