<template>
    <div class="type">
        <form style="margin-bottom:10px;">
            <a-row type="flex" justify="start">
                <a-col :span="7">
                    <a-row :gutter="10">
                        <a-col :span="17">
                            <a-input v-model="keyword" placeholder="请输入关键词" />
                        </a-col>
                        <a-col :span="3">
                            <a-button type="primary" @click="get_data()">搜索</a-button>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col :span="2">
                    <a-button type="primary" @click="add" v-if="$Func.check_rank('admingroup_add')">添加管理员分组</a-button>
                </a-col>
            </a-row>
        </form>
        <a-table :columns="columns" :data-source="tableData" @change="change_filter" :loading="loading" :pagination="page" bordered>
            <template slot="operation" slot-scope="text, record">
                <a @click="()=>{update_data(record)}" v-if="$Func.check_rank('admingroup_edit')">编辑</a>
                <a-divider type="vertical" />
                <a @click="()=>{delete_data(record)}" v-if="$Func.check_rank('admingroup_del')">删除</a>
            </template>
        </a-table>
        <a-drawer
          title="增加管理员分组"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="add_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="add_model=false"
          v-if="$Func.check_rank('admingroup_add')"
        >
            <a-form-model :model="group_add" :rules="rules" layout="vertical" @submit="add_data" ref="add_form">
                <a-form-model-item ref="title" prop="title" label="名称">
                    <a-input
                        v-model="group_add.title"
                        placeholder="请输入名称"
                    />
                </a-form-model-item>
                <a-form-model-item label="指定用户">
                    <a-select
                        mode="multiple"
                        label-in-value
                        :value="add_user_value"
                        placeholder="请选择用户"
                        :filter-option="false"
                        :not-found-content="add_fetching ? undefined : null"
                        @search="addReferrerSearch"
                        @change="addReferrerChange"
                    >
                        <a-spin v-if="add_fetching" slot="notFoundContent" size="small" />
                        <a-select-option v-for="d in add_user_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="区域条件">
                    <a-select mode="tags" v-model="group_add.query" style="width: 100%" placeholder="请填写区域条件" @change="addQueryChange">
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="rank" prop="rank" label="权限">
                    <a-tree
                        v-model="group_add.rank"
                        checkable
                        :expanded-keys="addExpandedKeys"
                        :auto-expand-parent="addExpandParent"
                        :selected-keys="addSelectedKeys"
                        :tree-data="treeData"
                        @expand="addExpand"
                        @select="addSelect"
                    />
                </a-form-model-item>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="add_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>

        <a-drawer
          title="修改管理员分组"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="update_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="update_model=false"
          v-if="$Func.check_rank('admingroup_edit')"
        >
            <a-form-model :model="group_update" :rules="rules" layout="vertical" ref="update_form" @submit="save_data">
                <a-form-model-item ref="title" prop="title" label="名称">
                    <a-input
                        v-model="group_update.title"
                        placeholder="请输入名称"
                    />
                </a-form-model-item>
                <a-form-model-item label="指定用户">
                    <a-select
                        mode="multiple"
                        label-in-value
                        :value="update_user_value"
                        placeholder="请选择用户"
                        :filter-option="false"
                        :not-found-content="update_fetching ? undefined : null"
                        @search="updateReferrerSearch"
                        @change="updateReferrerChange"
                    >
                        <a-spin v-if="update_fetching" slot="notFoundContent" size="small" />
                        <a-select-option v-for="d in update_user_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="区域条件">
                    <a-select mode="tags" v-model="group_update.query" style="width: 100%" placeholder="请填写区域条件" @change="updateQueryChange">
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="rank" prop="rank" label="权限">
                    <a-tree
                        v-model="group_update.rank"
                        checkable
                        :expanded-keys="updateExpandedKeys"
                        :auto-expand-parent="updateExpandParent"
                        :selected-keys="updateSelectedKeys"
                        :tree-data="treeData"
                        @expand="updateExpand"
                        @select="updateSelect"
                    />
                </a-form-model-item>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="update_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>
    </div>
</template>

<script>
    import Func from '../../common/func'
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 80,
        },
        {
            title: '名称',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '修改时间',
            dataIndex: 'modified',
            key: 'modified',
            width: 180,
        },
        {
            title: '操作',
            dataIndex: 'user_no',
            width: 180,
            key:'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ];
    const treeData = [
        {
            title: '询价管理',
            key: 'order',
            children: [
                {
                    title: '询价列表',
                    key: 'order_list',
                },
                {
                    title: '询价处理',
                    key: 'order_process',
                },
                {
                    title: '询价导出',
                    key: 'order_export',
                },
                {
                    title: '询价编辑',
                    key: 'order_edit',
                },
                {
                    title: '询价删除',
                    key: 'order_del',
                }
            ]
        },
        {
            title: '用户管理',
            key: 'user',
            children: [
                {
                    title: '用户列表',
                    key: 'user_list',
                },
                {
                    title: '用户创建',
                    key: 'user_add',
                },
                {
                    title: '用户编辑',
                    key: 'user_edit',
                },
                {
                    title: '用户删除',
                    key: 'user_del',
                },
                {
                    title: '银行账号审核',
                    key: 'user_check',
                },
                {
                    title: '用户启用禁用',
                    key: 'user_disabled',
                }
            ]
        },
        {
            title: '网点管理',
            key: 'branch',
            children: [
                {
                    title: '网点列表',
                    key: 'branch_list',
                },
                {
                    title: '网点创建',
                    key: 'branch_add',
                },
                {
                    title: '网点审核',
                    key: 'branch_check',
                },
                {
                    title: '网点编辑',
                    key: 'branch_edit',
                },
                {
                    title: '网点删除',
                    key: 'branch_del',
                }
            ]
        },
        {
            title: '日志管理',
            key: 'logs',
            children: [
                {
                    title: '后台日志',
                    key: 'logs_list',
                },
                {
                    title: '用户日志',
                    key: 'user_logs_list',
                }
            ]
        },
        {
            title: '系统消息管理',
            key: 'info',
            children: [
                {
                    title: '系统消息列表',
                    key: 'info_list',
                },
                {
                    title: '系统消息创建',
                    key: 'info_add',
                },
                {
                    title: '系统消息编辑',
                    key: 'info_edit',
                },
                {
                    title: '系统消息删除',
                    key: 'info_del',
                }
            ]
        },
        {
            title: '系统设置',
            key: 'setting',
            children: [
                {
                    title: '系统设置',
                    key: 'setting_index',
                },
                {
                    title: '管理员',
                    key: 'admin',
                    children: [
                        {
                            title: '管理员列表',
                            key: 'admin_list',
                        },
                        {
                            title: '管理员创建',
                            key: 'admin_add',
                        },
                        {
                            title: '管理员编辑',
                            key: 'admin_edit',
                        },
                        {
                            title: '管理员删除',
                            key: 'admin_del',
                        }
                    ]
                },
                {
                    title: '管理员分组',
                    key: 'admingroup',
                    children: [
                        {
                            title: '管理员分组列表',
                            key: 'admingroup_list',
                        },
                        {
                            title: '管理员分组创建',
                            key: 'admingroup_add',
                        },
                        {
                            title: '管理员分组编辑',
                            key: 'admingroup_edit',
                        },
                        {
                            title: '管理员分组删除',
                            key: 'admingroup_del',
                        }
                    ]
                },
                {
                    title: '电池类型',
                    key: 'type',
                    children: [
                        {
                            title: '电池类型列表',
                            key: 'type_list',
                        },
                        {
                            title: '电池类型创建',
                            key: 'type_add',
                        },
                        {
                            title: '电池类型编辑',
                            key: 'type_edit',
                        },
                        {
                            title: '电池类型删除',
                            key: 'type_del',
                        }
                    ]
                },
            ]
        },
    ];
    let add_timeout;
    let update_timeout;
    export default {
        name: 'Group',
        data(){
            return {
                columns,
                form: this.$form.createForm(this),
                keyword:'',
                tableData:[],
                loading:false,
                add_model:false,
                update_model:false,
                add_fetching: false,
                update_fetching: false,

                //权限列表 begin
                addExpandedKeys: ['0-0-0', '0-0-1'],
                addExpandParent: true,
                addSelectedKeys: [],
                updateExpandedKeys: ['0-0-0', '0-0-1'],
                updateExpandParent: true,
                updateSelectedKeys: [],
                treeData,
                //权限列表 end
                add_user_value:[],
                update_user_value:[],
                group_add:{
                    pid:0,
                    user_id:[],
                    title:'',
                    rank:[],
                    query:''
                },
                group_update:{
                    id:'',
                    user_id:[],
                    title:'',
                    rank:[],
                    query:''
                },
                rules: {
                    title:[{ required: true, message: '请输入名称', trigger: 'blur' }]
                },
                page:{
                    current:1,
                    total:0,
                    pageSize:10
                },
                add_user_list:[],
                update_user_list:[]
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {
            //指定用户 begin
            addReferrerSearch(keyword) {
                if (add_timeout) {
                    clearTimeout(add_timeout);
                    add_timeout = null;
                }
                add_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'users.search',
                        keyword
                    },(res) => {
                        this.add_user_list = res.info.list;
                    });
                }, 300);
            },
            addReferrerChange(value) {
                var user_id=[];
                value.forEach((v)=>{
                    user_id.push(v.key);
                })

                Object.assign(this, {
                    add_user_value:value,
                    add_user_list: [],
                    add_fetching: false
                });
                this.group_add.user_id=user_id;
            },
            addQueryChange(value){
                this.group_add.query=value;
            },
            updateReferrerSearch(keyword) {
                if (update_timeout) {
                    clearTimeout(update_timeout);
                    update_timeout = null;
                }
                update_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'users.search',
                        keyword
                    },(res) => {
                        this.update_user_list = res.info.list;
                    });
                }, 300);
            },
            updateReferrerChange(value) {
                var user_id=[];
                value.forEach((v)=>{
                    user_id.push(v.key);
                })
                Object.assign(this, {
                    update_user_value:value,
                    update_user_list: [],
                    update_fetching: false
                });

                this.group_update.user_id=user_id;
            },
            updateQueryChange(value){
                this.group_update.query=value;
            },
            //指定用户 end
            //权限列表 begin
            addExpand(addExpandedKeys) {
                console.log('addExpand', addExpandedKeys);
                this.addExpandedKeys = addExpandedKeys;
                this.addExpandParent = false;
            },
            addSelect(addSelectedKeys, info) {
                console.log('addSelect', info);
                this.addSelectedKeys = addSelectedKeys;
            },
            updateExpand(updateExpandedKeys) {
                console.log('updateExpand', updateExpandedKeys);
                this.updateExpandedKeys = updateExpandedKeys;
                this.updateExpandParent = false;
            },
            updateSelect(updateSelectedKeys, info) {
                console.log('updateSelect', info);
                this.updateSelectedKeys = updateSelectedKeys;
            },
            //权限列表 end
            change_filter(pagination){
                this.get_data(pagination.current);
            },
            get_data(page,tip) {
                var keyword = this.keyword;
                if (!page) {
                    page = this.page.current||1;
                }

                if(!tip){
                    this.loading = true
                }

                Func.url_get({
                    action:'admingroup',
                    keyword: keyword,
                    page: page
                },(res) => {
                    this.tableData = res.info.list;
                    var pagination={
                        pageSize:10,
                        total:res.info.foot.total,
                        current:parseInt(res.info.foot.current)
                    }
                    this.page=pagination;
                    this.loading = false
                });
            },
            add(){
                this.group_add={
                    pid:0,
                    user_id:[],
                    title:'',
                    rank:[],
                    query:''
                };
                this.add_model=true;
            },
            add_data(e){
                e.preventDefault();
                this.$refs.add_form.validate(valid => {
                    if (valid) {
                        Func.url_post({
                            action:'admingroup.add_data',
                            ...this.group_add
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.add_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },
            save_data(e){
                e.preventDefault();
                this.$refs.update_form.validate(valid => {
                    if (valid) {
                        Func.url_post({
                            action:'admingroup.save_data',
                            ...this.group_update
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.update_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },
            update_data(res){
                const item=JSON.parse(JSON.stringify(res));
                this.update_user_value=item.user_value,
                this.group_update=item;
                this.update_model=true;
            },
            delete_data(item){
                if(confirm('确定要删除吗')==true){
                    Func.url_get({
                        action:'admingroup.del_data',
                        id:item.id
                    },(res) => {
                        if(res.error===0){
                            this.$message.success(res.info);
                            this.get_data(1,true);
                        }else{
                            this.$message.error(res.info);
                        }
                    });
                }
            },

            goto_url(url,id){
                this.$router.push({
                    path:'/type/'+url,
                    params:{
                        id:id
                    }
                });
            }
        }
    }
</script>
<style lang="scss">
</style>