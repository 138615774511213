<template>
    <div class="admin">
        <form style="margin-bottom:10px;">
            <a-row type="flex" justify="start">
                <a-col :span="12">
                    <a-row :gutter="10">
                        <a-col :span="11">
                            <a-input v-model="keyword" placeholder="请输入关键词" />
                        </a-col>
                        <a-col :span="10">
                            <a-range-picker @change="onChange" />
                        </a-col>
                        <a-col :span="3">
                            <a-button type="primary" @click="get_data()">搜索</a-button>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
        </form>
        <a-table :columns="columns" :data-source="tableData" @change="change_filter" :loading="loading" :pagination="page" bordered>
        </a-table>
    </div>
</template>

<script>
    import Func from '../../common/func'
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 80,
        },
        {
            title: '操作日期',
            dataIndex: 'created',
            key: 'created',
            width: 150,
        },
        {
            title: '操作人',
            dataIndex: 'admin',
            key: 'admin',
            width: 120,
        },
        {
            title: '操作模块',
            dataIndex: 'opt',
            key: 'opt',
            width: 120,
        },
        {
            title: '操作内容',
            dataIndex: 'content',
            key: 'content',
            ellipsis: true
        }
    ];
    export default {
        name: 'Logs',
        data(){
            return {
                columns,
                keyword:'',
                range:[],
                tableData:[],
                loading:false,
                page:{
                    current:1,
                    total:0,
                    pageSize:10
                }
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {
            onChange(date, dateString) {
                this.range=dateString;
            },
            change_filter(pagination){
                this.get_data(pagination.current);
            },
            get_data(page,tip) {
                var keyword = this.keyword;
                if (!page) {
                    page = this.page.current||1;
                }

                if(!tip){
                    this.loading = true
                }

                Func.url_get({
                    action:'admin_logs',
                    keyword: keyword,
                    range:this.range,
                    page: page
                },(res) => {
                    this.tableData = res.info.list;
                    var pagination={
                        pageSize:10,
                        total:res.info.foot.total,
                        current:parseInt(res.info.foot.current)
                    }
                    this.page=pagination;
                    this.loading = false
                });
            },
            delete_data(item){
                if(confirm('确定要删除吗')==true){
                    Func.url_get({
                        action:'admin_logs.del_data',
                        id:item.id
                    },(res) => {
                        if(res.error===0){
                            this.$message.success(res.info);
                            this.get_data(1,true);
                        }else{
                            this.$message.error(res.info);
                        }
                    });
                }
            },
            goto_url(url,id){
                this.$router.push({
                    path:'/admin/'+url,
                    params:{
                        id:id
                    }
                });
            }
        }
    }
</script>
<style lang="scss">

</style>