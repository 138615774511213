<template>
    <div class="type">
        <form style="margin-bottom:10px;">
            <a-row type="flex" justify="start">
                <a-col :span="7">
                    <a-row :gutter="10">
                        <a-col :span="17">
                            <a-input v-model="keyword" placeholder="请输入关键词" />
                        </a-col>
                        <a-col :span="3">
                            <a-button type="primary" @click="get_data()">搜索</a-button>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col :span="2">
                    <a-button type="primary" @click="add" v-if="$Func.check_rank('type_add')">添加电池类型</a-button>
                </a-col>
            </a-row>
        </form>
        <a-table :columns="columns" :data-source="tableData" @change="change_filter" :loading="loading" :pagination="page" bordered>
            <template slot="operation" slot-scope="text, record">
                <a @click="()=>{update_data(record)}"  v-if="$Func.check_rank('type_edit')">编辑</a>
                <a-divider type="vertical" />
                <a @click="()=>{delete_data(record)}"  v-if="$Func.check_rank('type_del')">删除</a>
            </template>
        </a-table>
        <a-drawer
          title="增加电池类型"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="add_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="add_model=false"
           v-if="$Func.check_rank('type_add')"
        >
            <a-form-model :model="type_add" :rules="rules" layout="vertical" @submit="add_data" ref="add_form">
                <a-form-model-item ref="title" prop="title" label="名称">
                    <a-input
                        v-model="type_add.title"
                        placeholder="请输入名称"
                    />
                </a-form-model-item>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="add_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>

        <a-drawer
          title="修改电池类型"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="update_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="update_model=false"
           v-if="$Func.check_rank('type_edit')"
        >
            <a-form-model :model="type_update" :rules="rules" layout="vertical" ref="update_form" @submit="save_data">
                <a-form-model-item ref="title" prop="title" label="名称">
                    <a-input
                        v-model="type_update.title"
                        placeholder="请输入名称"
                    />
                </a-form-model-item>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="update_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>
    </div>
</template>

<script>
    import Func from '../../common/func'
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 80,
        },
        {
            title: '名称',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '操作',
            dataIndex: 'user_no',
            width: 180,
            key:'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ];
    export default {
        name: 'Type',
        data(){
            return {
                columns,
                form: this.$form.createForm(this),
                keyword:'',
                tableData:[],
                loading:false,
                add_model:false,
                update_model:false,
                type_add:{
                    pid:0,
                    title:''
                },
                type_update:{
                    id:'',
                    pid:'',
                    title:''
                },
                rules: {
                    title:[{ required: true, message: '请输入名称', trigger: 'blur' }]
                },
                page:{
                    current:1,
                    total:0,
                    pageSize:10
                }
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {
            change_filter(pagination){
                this.get_data(pagination.current);
            },
            get_data(page,tip) {
                var keyword = this.keyword;
                if (!page) {
                    page = this.page.current||1;
                }

                if(!tip){
                    this.loading = true
                }

                Func.url_get({
                    action:'type',
                    keyword: keyword,
                    page: page
                },(res) => {
                    this.tableData = res.info.list;
                    var pagination={
                        pageSize:10,
                        total:res.info.foot.total,
                        current:parseInt(res.info.foot.current)
                    }
                    this.page=pagination;
                    this.loading = false
                });
            },
            add(){
                this.type_add={
                    pid:0,
                    title:''
                };
                this.add_model=true;
            },
            add_data(e){
                e.preventDefault();
                this.$refs.add_form.validate(valid => {
                    if (valid) {
                        var {pid,title}=this.type_add;
                        Func.url_post({
                            action:'type.add_data',
                            pid:pid,
                            title:title
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.add_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },
            save_data(e){
                e.preventDefault();
                this.$refs.update_form.validate(valid => {
                    if (valid) {
                        var {id,pid,title}=this.type_update;
                        Func.url_post({
                            action:'type.save_data',
                            id:id,
                            pid:pid,
                            title:title
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.update_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },
            update_data(res){
                const item=JSON.parse(JSON.stringify(res));
                this.type_update=item;
                this.update_model=true;
            },
            delete_data(item){
                if(confirm('确定要删除吗')==true){
                    Func.url_get({
                        action:'type.del_data',
                        id:item.id
                    },(res) => {
                        if(res.error===0){
                            this.$message.success(res.info);
                            this.get_data(1,true);
                        }else{
                            this.$message.error(res.info);
                        }
                    });
                }
            },
            goto_url(url,id){
                this.$router.push({
                    path:'/type/'+url,
                    params:{
                        id:id
                    }
                });
            }
        }
    }
</script>
<style lang="scss">
</style>