<template>
    <div class="users">
        <form style="margin-bottom:10px;">
            <a-row type="flex" justify="start">
                <a-col :span="12">
                    <a-row :gutter="10">
                        <a-col :span="6">
                            <a-input v-model="keyword" placeholder="请输入关键词" />
                        </a-col>
                        <a-col :span="9">
                            <a-select
                                :value="search_user_value"
                                show-search
                                placeholder="请输入关键词搜索上级用户"
                                :show-arrow="true"
                                :filter-option="false"
                                :not-found-content="search_fetching ? undefined : null"
                                @search="searchUserSearch"
                                @change="searchUserChange"
                            >
                                <a-select-option key="">所有上级用户</a-select-option>
                                <a-spin v-if="search_fetching" slot="notFoundContent" size="small" />
                                <a-select-option v-for="d in search_user_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="6">
                            <a-select v-model="check" style="width: 100%" placeholder="请选择审核状态">
                                <a-select-option value="">所有审核状态</a-select-option>
                                <a-select-option :value="0">未处理</a-select-option>
                                <a-select-option :value="1">已处理</a-select-option>
                                <a-select-option :value="2">审核通过</a-select-option>
                                <a-select-option :value="3">审核拒绝</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="3">
                            <a-button type="primary" @click="get_data()">搜索</a-button>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="2">
                    <a-button type="primary" @click="add" v-if="$Func.check_rank('user_add')">添加用户</a-button>
                </a-col>
            </a-row>
        </form>
        <a-table :columns="columns" :data-source="tableData" @change="change_filter" :loading="loading" :pagination="page" bordered>
            <template slot="avatar" slot-scope="text, record">
                <img :src="record.avatar" style="max-width:50px;max-height:50px;" />
            </template>
            <template slot="referrer" slot-scope="text, record">
                <div v-if="record.referrer">{{record.referrer.truename}}<br />
                {{record.referrer.mobile}}</div>
            </template>
            <template slot="user_type" slot-scope="text, record">
                <div>
                    <a-tag color="pink" v-if="record.is_agent_str">{{record.is_agent_str}}</a-tag>
                    <a-tag color="green" v-if="record.is_sales_str">{{record.is_sales_str}}</a-tag>
                </div>
            </template>
            <template slot="operation" slot-scope="text, record">
                <a @click="()=>{disabled_data(record)}" v-if="$Func.check_rank('user_disabled') && record.status==1">禁用</a>
                <a @click="()=>{open_data(record)}" v-if="$Func.check_rank('user_disabled') && record.status==0">启用</a>
                <a-divider type="vertical" />
                <a @click="()=>{update_data(record)}" v-if="$Func.check_rank('user_edit')">编辑</a>
                <a-divider type="vertical" />
                <a @click="()=>{delete_data(record)}" v-if="$Func.check_rank('user_del')">删除</a>
            </template>
        </a-table>
        <a-drawer
          title="增加用户"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="add_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="add_model=false"
          v-if="$Func.check_rank('user_add')"
        >
            <a-form-model :model="users_add" :rules="rules" layout="vertical" @submit="add_data" ref="add_form">
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="代理商编号">
                            <a-input v-model="users_add.agent_no" placeholder="请输入代理商编号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item label="允许代理">
                            <a-radio-group default-value="1" v-model="users_add.is_agent" button-style="solid">
                                <a-radio-button :value="0">否</a-radio-button>
                                <a-radio-button :value="1">是</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                    <!-- <a-col :span="8">
                        <a-form-model-item label="网点">
                            <a-radio-group default-value="1" v-model="users_add.is_branch" button-style="solid">
                                <a-radio-button :value="0">否</a-radio-button>
                                <a-radio-button :value="1">是</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col> -->
                    <a-col :span="6">
                        <a-form-model-item label="业务员">
                            <a-radio-group default-value="1" v-model="users_add.is_sales" button-style="solid">
                                <a-radio-button :value="0">否</a-radio-button>
                                <a-radio-button :value="1">是</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="手机号">
                            <a-input v-model="users_add.mobile" placeholder="请输入手机号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="姓名">
                            <a-input v-model="users_add.truename" placeholder="请输入姓名" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="微信唯一标识">
                            <a-input v-model="users_add.openid" placeholder="请输入微信唯一标识" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="昵称">
                            <a-input v-model="users_add.nickname" placeholder="请输入昵称" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="推荐人">
                            <a-select
                                show-search
                                :value="users_add.referrer_id"
                                placeholder="请输入关键词搜索选择"
                                :default-active-first-option="true"
                                :show-arrow="true"
                                :filter-option="false"
                                :not-found-content="null"
                                @search="addReferrerSearch"
                                @change="addReferrerChange"
                            >
                                <a-select-option v-for="d in add_user_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="身份证号">
                            <a-input v-model="users_add.account_card_no" placeholder="请输入身份证号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="户名">
                            <a-input v-model="users_add.account_truename" placeholder="请输入户名" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="卡号">
                            <a-input v-model="users_add.account_number" placeholder="请输入卡号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="开户行">
                            <a-input v-model="users_add.account_bank" placeholder="请输入开户行" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="收款帐号审核">
                            <a-radio-group default-value="0" v-model="users_add.account_check" button-style="solid">
                                <a-radio-button :value="0">未提交</a-radio-button>
                                <a-radio-button :value="1">审核通过</a-radio-button>
                                <a-radio-button :value="2">已提交</a-radio-button>
                                <a-radio-button :value="3">审核拒绝</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="add_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>
        <a-drawer
          title="修改用户"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="update_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="update_model=false"
          v-if="$Func.check_rank('user_edit')"
        >
            <a-form-model :model="users_update" :rules="rules" layout="vertical" @submit="save_data" ref="update_form">
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="代理商编号">
                            <a-input v-model="users_update.agent_no" placeholder="请输入代理商编号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item label="允许代理">
                            <a-radio-group default-value="1" v-model="users_update.is_agent" button-style="solid">
                                <a-radio-button :value="0">否</a-radio-button>
                                <a-radio-button :value="1">是</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                    <!-- <a-col :span="8">
                        <a-form-model-item label="网点">
                            <a-radio-group default-value="1" v-model="users_update.is_branch" button-style="solid">
                                <a-radio-button :value="0">否</a-radio-button>
                                <a-radio-button :value="1">是</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col> -->
                    <a-col :span="6">
                        <a-form-model-item label="业务员">
                            <a-radio-group default-value="1" v-model="users_update.is_sales" button-style="solid">
                                <a-radio-button :value="0">否</a-radio-button>
                                <a-radio-button :value="1">是</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="手机号">
                            <a-input v-model="users_update.mobile" placeholder="请输入手机号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="姓名">
                            <a-input v-model="users_update.truename" placeholder="请输入姓名" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="微信唯一标识">
                            <a-input v-model="users_update.openid" placeholder="请输入微信唯一标识" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="昵称">
                            <a-input v-model="users_update.nickname" placeholder="请输入昵称" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="推荐人">
                            <a-select
                                show-search
                                :value="users_update.referrer_id"
                                placeholder="请输入关键词搜索选择"
                                :default-active-first-option="true"
                                :show-arrow="true"
                                :filter-option="false"
                                :not-found-content="null"
                                @search="updateReferrerSearch"
                                @change="updateReferrerChange"
                            >
                                <a-select-option v-for="d in update_user_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="update_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>
        <a-drawer
          title="审核收款帐号"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="check_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="check_model=false"
          v-if="$Func.check_rank('user_check')"
        >
            <a-form-model :model="users_check" :rules="check_rules" layout="vertical" @submit="save_check" ref="check_form">
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="身份证号">
                            <a-input v-model="users_check.account_card_no" placeholder="请输入身份证号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="户名">
                            <a-input v-model="users_check.account_truename" placeholder="请输入户名" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="卡号">
                            <a-input v-model="users_check.account_number" placeholder="请输入卡号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="开户行">
                            <a-input v-model="users_check.account_bank" placeholder="请输入开户行" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="收款帐号审核">
                            <a-radio-group default-value="0" v-model="users_check.account_check" button-style="solid">
                                <a-radio-button :value="0">未提交</a-radio-button>
                                <a-radio-button :value="2">已提交</a-radio-button>
                                <a-radio-button :value="1">审核通过</a-radio-button>
                                <a-radio-button :value="3">审核拒绝</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="check_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>
        <a-drawer
          title="禁用用户"
          :width="500"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="disabled_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="disabled_model=false"
          v-if="$Func.check_rank('user_disabled')"
        >
            <a-form-model :model="users_disabled" :rules="disabled_rules" layout="vertical" @submit="save_disabled" ref="disabled_form">
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item label="禁用天数">
                            <a-input-number v-model="users_disabled.day" placeholder="请输入禁用天数" style="width:35%;" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="禁用原因">
                            <a-textarea v-model="users_disabled.note" rows="5" placeholder="请输入禁用原因" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="disabled_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>
    </div>
</template>

<script>
    import Func from '../common/func'
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 80,
        },
        {
            title: '头像',
            dataIndex: 'avatar',
            key: 'avatar',
            scopedSlots: { customRender: 'avatar' },
            width: 80,
        },
        {
            title: '姓名',
            dataIndex: 'truename',
            key: 'truename',
            width: 100,
        },
        {
            title: '身份',
            dataIndex: 'id',
            width: 150,
            ellipsis: true,
            key:'user_type',
            scopedSlots: { customRender: 'user_type' },
        },
        {
            title: '推荐人',
            dataIndex: 'referrer',
            width: 130,
            ellipsis: true,
            key:'referrer',
            scopedSlots: { customRender: 'referrer' },
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
            width: 130,
        },
        {
            title: '昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: 110,
        },
        {
            title: '网点数',
            dataIndex: 'branch_total',
            key: 'branch_total',
            align:'center',
            width: 110,
        },
        {
            title: '发展用户数',
            dataIndex: 'referrer_total',
            key: 'referrer_total',
            align:'center',
            width: 110,
        },
        {
            title: '询价单数',
            dataIndex: 'order_total',
            key: 'order_total',
            align:'center',
            width: 110,
        },
        {
            title: '询价金额',
            dataIndex: 'order_price',
            key: 'order_price',
            align:'center',
            width: 110,
        },
        {
            title: '收款信息审核',
            dataIndex: 'account_check_str',
            key: 'account_check_str',
            width: 120,
        },
        {
            title: '状态',
            dataIndex: 'status_str',
            key: 'status_str',
            width: 65,
        },
        {
            title: '创建时间',
            dataIndex: 'created',
            key: 'created',
            ellipsis: true,
            width: 180,
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 200,
            key:'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ];
    let search_timeout;
    let add_timeout;
    let update_timeout;
    export default {
        name: 'Users',
        data(){
            return {
                columns,
                form: this.$form.createForm(this),
                keyword:'',
                check:'',
                tableData:[],
                loading:false,
                add_model:false,
                update_model:false,
                check_model:false,
                disabled_model:false,

                search_fetching: false,
                search_user_value:undefined,
                search_user_list:[],

                users_add:{
                    agent_no:'',
                    is_agent:1,
                    is_branch:1,
                    is_sales:1,
                    mobile:'',
                    truename:'',
                    openid:'',
                    nickname:'',
                    avatar:'',
                    referrer_id:undefined,
                    referrer_code:'',
                    is_realname:'',
                    realname_note:'',
                    status:'',
                    check:'',
                    check_note:'',
                    account_card_no:'',
                    account_truename:'',
                    account_number:'',
                    account_bank:'',
                },
                users_update:{
                    agent_no:'',
                    is_agent:1,
                    is_branch:1,
                    is_sales:1,
                    mobile:'',
                    truename:'',
                    openid:'',
                    nickname:'',
                    avatar:'',
                    referrer_id:undefined,
                    referrer_code:'',
                    is_realname:'',
                    realname_note:'',
                    status:'',
                    check:'',
                    check_note:'',
                    account_card_no:'',
                    account_truename:'',
                    account_number:'',
                    account_bank:'',
                },
                users_check:{
                    id:'',
                    account_card_no:'',
                    account_truename:'',
                    account_number:'',
                    account_bank:'',
                    account_check:'',
                },
                users_disabled:{
                    id:'',
                    day:'',
                    note:''
                },
                rules: {
                    truename:[{ required: true, message: '请输入姓名', trigger: 'blur' }],
                    mobile:[{ required: true, message: '请输入手机号', trigger: 'blur' }],
                },
                check_rules: {
                    account_card_no:[{ required: true, message: '请输入身份证号', trigger: 'blur' }],
                    account_truename:[{ required: true, message: '请输入户名', trigger: 'blur' }],
                    account_number:[{ required: true, message: '请输入卡号', trigger: 'blur' }],
                    account_bank:[{ required: true, message: '请输入开户行', trigger: 'blur' }],
                    account_check:[{ required: true, message: '请选择收款账号审核状态', trigger: 'blur' }],
                },
                disabled_rules: {
                    day:[{ required: true, message: '请输入禁用天数', trigger: 'blur' }],
                    note:[{ required: true, message: '请输入禁用原因', trigger: 'blur' }]
                },
                page:{
                    current:1,
                    total:0,
                    pageSize:10
                },
                add_user_list:[],
                update_user_list:[]
            }
        },
        mounted() {
            if(this.$route.query.check){
                this.check=parseInt(this.$route.query.check);
            }
            
            this.get_data();
        },
        methods: {
            //指定用户 begin
            searchUserSearch(keyword) {
                if (search_timeout) {
                    clearTimeout(search_timeout);
                    search_timeout = null;
                }
                search_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'users.search',
                        keyword
                    },(res) => {
                        this.search_user_list = res.info.list;
                    });
                }, 300);
            },
            searchUserChange(value) {
                this.search_user_value=value;
            },

            changeUpdateDate(date,dateString){
                this.users_update.disabled_exp=dateString;
            },
            changeAddDate(date,dateString){
                this.users_add.disabled_exp=dateString;
            },
            change_filter(pagination){
                this.get_data(pagination.current);
            },
            get_data(page,tip) {
                var keyword = this.keyword;
                if (!page) {
                    page = this.page.current||1;
                }

                if(!tip){
                    this.loading = true
                }

                Func.url_get({
                    action:'users',
                    keyword: keyword,
                    check:this.check,
                    referrer_id:this.search_user_value||'',
                    page: page
                },(res) => {
                    this.tableData = res.info.list;
                    var pagination={
                        pageSize:10,
                        total:res.info.foot.total,
                        current:parseInt(res.info.foot.current)
                    }
                    this.page=pagination;
                    this.loading = false
                });
            },
            addReferrerSearch(keyword) {
                if (add_timeout) {
                    clearTimeout(add_timeout);
                    add_timeout = null;
                }
                add_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'users.search',
                        keyword
                    },(res) => {
                        this.add_user_list = res.info.list;
                    });
                }, 300);
            },
            addReferrerChange(value) {
                this.users_add.referrer_id = value;
            },
            updateReferrerSearch(keyword) {
                if (update_timeout) {
                    clearTimeout(update_timeout);
                    update_timeout = null;
                }
                update_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'users.search',
                        keyword
                    },(res) => {
                        this.update_user_list = res.info.list;
                    });
                }, 300);
            },
            updateReferrerChange(value) {
                this.users_update.referrer_id = value;
            },
            add(){
                this.users_add={
                    agent_no:'',
                    is_agent:1,
                    is_branch:1,
                    is_sales:1,
                    mobile:'',
                    truename:'',
                    openid:'',
                    nickname:'',
                    avatar:'',
                    referrer_id:undefined,
                    referrer_code:'',
                    is_realname:'',
                    realname_note:'',
                    status:'',
                    check:'',
                    check_note:'',
                    account_card_no:'',
                    account_truename:'',
                    account_number:'',
                    account_bank:'',
                };
                this.add_model=true;
            },
            add_data(e){
                e.preventDefault();
                this.$refs.add_form.validate(valid => {
                    if (valid) {
                        Func.url_post({
                            action:'users.add_data',
                            ...this.users_add
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.add_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },
            save_data(e){
                e.preventDefault();
                this.$refs.update_form.validate(valid => {
                    if (valid) {
                        Func.url_post({
                            action:'users.save_data',
                            ...this.users_update
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.update_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },
            update_data(res){
                const item=JSON.parse(JSON.stringify(res));
                Func.url_get({
                    action:'users.get_data',
                    id: item.id
                },(res) => {
                    this.users_update = res.info;

                    Func.url_get({
                        action:'users.search_one',
                        keyword:res.info.referrer_id
                    },(res) => {
                        this.update_user_list = res.info.list;
                    });

                    this.update_model=true;
                });
            },

            //审核用户
            check_data(item){
                Func.url_get({
                    action:'users.get_data',
                    id:item.id
                },(res) => {
                    this.users_check = res.info;

                    this.check_model=true;
                });
            },
            save_check(e){
                e.preventDefault();
                this.$refs.check_form.validate(valid => {
                    if (valid) {
                        Func.url_post({
                            action:'users.check_data',
                            ...this.users_check
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.check_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },

            //禁用用户
            disabled_data(item){
                this.users_disabled={
                    id:item.id,
                    day:'',
                    note:''
                };
                this.disabled_model=true;
            },
            save_disabled(e){
                e.preventDefault();
                this.$refs.disabled_form.validate(valid => {
                    if (valid) {
                        Func.url_post({
                            action:'users.disabled_data',
                            ...this.users_disabled
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.disabled_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },

            //启用
            open_data(item){
                Func.url_post({
                    action:'users.open_data',
                    id:item.id
                },(res) => {
                    if(res.error===0){
                        this.$message.success(res.info);
                        this.get_data(1,true);
                    }else{
                        this.$message.error(res.info);
                    }
                });
            },

            delete_data(item){
                if(confirm('确定要删除吗')==true){
                    Func.url_get({
                        action:'users.del_data',
                        id:item.id
                    },(res) => {
                        if(res.error===0){
                            this.$message.success(res.info);
                            this.get_data(1,true);
                        }else{
                            this.$message.error(res.info);
                        }
                    });
                }
            }
        }
    }
</script>
<style lang="scss">
</style>