<template>
    <div class="admin">
        <form style="margin-bottom:10px;">
            <a-row type="flex" justify="start">
                <a-col :span="15">
                    <a-row :gutter="10">
                        <a-col :span="6">
                            <a-input v-model="keyword" placeholder="请输入关键词" />
                        </a-col>
                        <a-col :span="5">
                            <a-select
                                :value="search_user_value"
                                show-search
                                placeholder="请输入关键词搜索用户"
                                :show-arrow="true"
                                :filter-option="false"
                                :not-found-content="search_fetching ? undefined : null"
                                @search="searchBranchSearch"
                                @change="searchBranchChange"
                            >
                                <a-select-option key="">所有用户</a-select-option>
                                <a-spin v-if="search_fetching" slot="notFoundContent" size="small" />
                                <a-select-option v-for="d in search_user_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="4">
                            <a-select v-model="check" style="width: 100%" placeholder="请选择审核状态">
                                <a-select-option value="">所有审核状态</a-select-option>
                                <a-select-option :value="0">待审核</a-select-option>
                                <a-select-option :value="1">审核通过</a-select-option>
                                <a-select-option :value="2">审核拒绝</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="6">
                            <a-range-picker @change="onChange" />
                        </a-col>
                        <a-col :span="3">
                            <a-button type="primary" @click="get_data()">搜索</a-button>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col :span="2" v-if="$Func.check_rank('branch_add')">
                    <a-button type="primary" @click="add_model=true">添加网点</a-button>
                </a-col>
            </a-row>
        </form>
        <a-table :columns="columns" :data-source="tableData" @change="change_filter" :loading="loading" :pagination="page" bordered>
            <template slot="operation" slot-scope="text, record">
                <a @click="()=>{check_data(record)}" v-if="$Func.check_rank('branch_check')">审核</a>
                <a-divider type="vertical" v-if="$Func.check_rank('branch_check')" />
                <a @click="()=>{update_data(record)}" v-if="$Func.check_rank('branch_edit')">编辑</a>
                <a-divider type="vertical" />
                <a @click="()=>{delete_data(record)}" v-if="$Func.check_rank('branch_del')">删除</a>
            </template>
        </a-table>
        <a-drawer
          title="增加网点"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="add_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="add_model=false"
          v-if="$Func.check_rank('branch_add')"
        >
            <a-form-model :model="branch_add" :rules="add_rules" layout="vertical" @submit="add_data" ref="add_form">
                <a-form-model-item label="用户">
                    <a-select
                        :value="add_user_value"
                        show-search
                        placeholder="请输入关键词搜索选择"
                        :show-arrow="true"
                        :filter-option="false"
                        :not-found-content="add_fetching ? undefined : null"
                        @search="addBranchSearch"
                        @change="addBranchChange"
                    >
                        <a-spin v-if="add_fetching" slot="notFoundContent" size="small" />
                        <a-select-option v-for="d in add_user_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="title" prop="title" label="网点名">
                    <a-input v-model="branch_add.title" />
                </a-form-model-item>
                <a-form-model-item ref="address" prop="address" label="地址">
                    <a-input v-model="branch_add.address" />
                </a-form-model-item>
                <a-form-model-item ref="gps" prop="gps" label="gps">
                    <a-input v-model="branch_add.gps" />
                </a-form-model-item>
                <a-form-model-item ref="contact" prop="contact" label="联系人">
                    <a-input v-model="branch_add.contact" />
                </a-form-model-item>
                <a-form-model-item ref="phone" prop="phone" label="手机号">
                    <a-input v-model="branch_add.phone" />
                </a-form-model-item>
                <a-form-model-item ref="sms_phone" prop="sms_phone" label="接收短信手机号">
                    <a-input v-model="branch_add.sms_phone" />
                </a-form-model-item>
                <a-form-model-item ref="check" prop="check" label="审核">
                    <a-radio-group default-value="1" v-model="branch_add.check" button-style="solid">
                        <a-radio-button :value="0">待审核</a-radio-button>
                        <a-radio-button :value="1">审核通过</a-radio-button>
                        <a-radio-button :value="2">审核拒绝</a-radio-button>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="check_note" prop="check_note" label="拒绝原因" v-show="branch_add.check===2">
                    <a-textarea v-model="branch_add.check_note" />
                </a-form-model-item>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="add_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>
        <a-drawer
          title="修改网点"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="update_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="update_model=false"
          v-if="$Func.check_rank('branch_edit')"
        >
            <a-form-model :model="branch_update" :rules="update_rules" layout="vertical" @submit="save_data" ref="update_form">
                <a-form-model-item label="用户">
                    <a-select
                        show-search
                        :value="update_user_value"
                        placeholder="请输入关键词搜索选择"
                        :show-arrow="true"
                        :filter-option="false"
                        :not-found-content="update_fetching ? undefined : null"
                        @search="updateBranchSearch"
                        @change="updateBranchChange"
                    >
                        <a-spin v-if="update_fetching" slot="notFoundContent" size="small" />
                        <a-select-option v-for="d in update_user_list" :key="d.id">{{ d.truename+' ('+d.mobile+')' }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="title" prop="title" label="网点名">
                    <a-input v-model="branch_update.title" />
                </a-form-model-item>
                <a-form-model-item ref="address" prop="address" label="地址">
                    <a-input v-model="branch_update.address" />
                </a-form-model-item>
                <a-form-model-item ref="gps" prop="gps" label="gps">
                    <a-input v-model="branch_update.gps" />
                </a-form-model-item>
                <a-form-model-item ref="contact" prop="contact" label="联系人">
                    <a-input v-model="branch_update.contact" />
                </a-form-model-item>
                <a-form-model-item ref="phone" prop="phone" label="手机号">
                    <a-input v-model="branch_update.phone" />
                </a-form-model-item>
                <a-form-model-item ref="sms_phone" prop="sms_phone" label="接收短信手机号">
                    <a-input v-model="branch_update.sms_phone" />
                </a-form-model-item>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="update_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>
        <a-drawer
          title="审核网点"
          :width="720"
          :maskStyle="{backgroundColor: '#00000010'}"
          :visible="check_model"
          :body-style="{ paddingBottom: '80px' }"
          @close="check_model=false"
          v-if="$Func.check_rank('branch_check')"
        >
            <a-form-model :model="branch_check" :rules="check_rules" layout="vertical" @submit="check_save" ref="check_form">
                <a-form-model-item ref="check" prop="check" label="审核状态">
                    <a-radio-group default-value="1" v-model="branch_check.check" button-style="solid">
                        <a-radio-button :value="0">待审核</a-radio-button>
                        <a-radio-button :value="1">审核通过</a-radio-button>
                        <a-radio-button :value="2">审核拒绝</a-radio-button>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="check_note" prop="check_note" label="拒绝原因" v-show="branch_check.check===2">
                    <a-textarea v-model="branch_check.check_note" />
                </a-form-model-item>
                <div :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }">
                    <a-button :style="{ marginRight: '8px' }" @click="check_model=false">
                        取消
                    </a-button>
                    <a-button type="primary" html-type="submit">
                        确定
                    </a-button>
                </div>
            </a-form-model>
        </a-drawer>
    </div>
</template>

<script>
    import Func from '../common/func'
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 80,
        },
        {
            title: '用户',
            dataIndex: 'users_truename',
            key: 'users_truename',
            width: 120,
        },
        {
            title: '网点名',
            dataIndex: 'title',
            key: 'title',
            width: 120,
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '联系人',
            dataIndex: 'contact',
            key: 'contact',
            width: 120,
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
            width: 120,
        },
        {
            title: '审核',
            dataIndex: 'check_str',
            key: 'check_str',
            width: 80,
        },
        {
            title: '创建时间',
            dataIndex: 'created',
            key: 'created',
            width: 180,
        },
        {
            title: '修改时间',
            dataIndex: 'modified',
            key: 'modified',
            width: 180,
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 180,
            key:'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ];
    let search_timeout;
    let add_timeout;
    let update_timeout;
    export default {
        name: 'Branch',
        data(){
            return {
                columns,
                form: this.$form.createForm(this),
                keyword:'',
                check:'',
                range:[],
                tableData:[],
                loading:false,
                add_model:false,
                update_model:false,
                check_model:false,
                search_fetching: false,
                search_user_value:undefined,
                search_user_list:[],

                add_fetching: false,
                add_user_value:undefined,
                add_user_list:[],
                update_fetching: false,
                update_user_value:undefined,
                update_user_list:[],
                group_list:[],
                branch_add:{
                    uid:'',
                    title:'',
                    address:'',
                    gps:'',
                    contact:'',
                    phone:'',
                    sms_phone:'',
                    check:1,
                    check_note:''
                },
                branch_update:{
                    id:0,
                    uid:'',
                    title:'',
                    address:'',
                    gps:'',
                    contact:'',
                    phone:'',
                    sms_phone:'',
                    check:1,
                    check_note:''
                },
                branch_check:{
                    id:0,
                    check:1,
                    check_note:''
                },
                add_rules: {
                    username:[{ required: true, message: '请输入账号', trigger: 'blur' }],
                    password:[{ required: true, message: '请输入密码', trigger: 'blur' }],
                },
                update_rules: {
                    username:[{ required: true, message: '请输入账号', trigger: 'blur' }],
                },
                check_rules: {
                    check:[{ required: true, message: '请选择审核状态', trigger: 'blur' }],
                },
                page:{
                    current:1,
                    total:0,
                    pageSize:10
                }
            }
        },
        mounted() {
            if(this.$route.query.check){
                this.check=parseInt(this.$route.query.check);
            }
            this.get_data();
        },
        methods: {
            onChange(date, dateString) {
                this.range=dateString;
            },
            //指定用户 begin
            searchBranchSearch(keyword) {
                if (search_timeout) {
                    clearTimeout(search_timeout);
                    search_timeout = null;
                }
                search_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'users.search',
                        keyword
                    },(res) => {
                        this.search_user_list = res.info.list;
                    });
                }, 300);
            },
            searchBranchChange(value) {
                this.search_user_value=value;
            },
            //指定用户 begin
            addBranchSearch(keyword) {
                if (add_timeout) {
                    clearTimeout(add_timeout);
                    add_timeout = null;
                }
                add_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'users.search',
                        keyword
                    },(res) => {
                        this.add_user_list = res.info.list;
                    });
                }, 300);
            },
            addBranchChange(value) {
                this.branch_add.uid = value;
                this.add_user_value=value;
            },
            updateBranchSearch(keyword) {
                if (update_timeout) {
                    clearTimeout(update_timeout);
                    update_timeout = null;
                }
                update_timeout = setTimeout(()=>{
                    Func.url_get({
                        action:'users.search',
                        keyword
                    },(res) => {
                        this.update_user_list = res.info.list;
                    });
                }, 300);
            },
            updateBranchChange(value) {
                this.branch_update.uid = value;
                this.update_user_value=value;
            },
            //指定用户 end
            change_filter(pagination){
                this.get_data(pagination.current);
            },
            get_data(page,tip) {
                var keyword = this.keyword;
                if (!page) {
                    page = this.page.current||1;
                }

                if(!tip){
                    this.loading = true
                }

                Func.url_get({
                    action:'branch',
                    keyword: keyword,
                    range:this.range,
                    check:this.check,
                    uid:this.search_user_value||'',
                    page: page
                },(res) => {
                    this.tableData = res.info.list;
                    var pagination={
                        pageSize:10,
                        total:res.info.foot.total,
                        current:parseInt(res.info.foot.current)
                    }
                    this.page=pagination;
                    this.loading = false
                });
            },

            add_data(e){
                e.preventDefault();
                this.$refs.add_form.validate(valid => {
                    if (valid) {
                        Func.url_post({
                            action:'branch.add_data',
                            ...this.branch_add
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.add_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },

            save_data(e){
                e.preventDefault();
                this.$refs.update_form.validate(valid => {
                    if (valid) {
                        Func.url_post({
                            action:'branch.save_data',
                            ...this.branch_update
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.update_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },
            update_data(res){
                const item=JSON.parse(JSON.stringify(res));
                item.password='';
                Func.url_get({
                    action:'users.search_one',
                    keyword:item.uid
                },(res) => {
                    this.update_user_list = res.info.list;
                });
                this.update_user_value=item.uid;
                this.branch_update=item;
                this.update_model=true;
            },

            check_save(e){
                e.preventDefault();
                this.$refs.check_form.validate(valid => {
                    if (valid) {
                        Func.url_post({
                            action:'branch.check_data',
                            ...this.branch_check
                        },(res) => {
                            if(res.error===0){
                                this.$message.success(res.info);
                                this.check_model=false;
                                this.get_data(1,true);
                            }else{
                                this.$message.error(res.info);
                            }
                        });
                    }else{
                        return false;
                    }
                });
            },
            check_data(item){
                this.branch_check=item;
                this.check_model=true;
            },

            delete_data(item){
                if(confirm('确定要删除吗')==true){
                    Func.url_get({
                        action:'branch.del_data',
                        id:item.id
                    },(res) => {
                        if(res.error===0){
                            this.$message.success(res.info);
                            this.get_data(1,true);
                        }else{
                            this.$message.error(res.info);
                        }
                    });
                }
            },
            goto_url(url,id){
                this.$router.push({
                    path:'/admin/'+url,
                    params:{
                        id:id
                    }
                });
            }
        }
    }
</script>
<style lang="scss">
</style>