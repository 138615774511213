<template>
	<div class="users">
		<a-form-model :model="setting" :rules="rules" layout="vertical" @submit="save_data" ref="setting_form"
			style="width:500px;">
			<a-divider orientation="left">
				基础配置
			</a-divider>

			<a-form-model-item ref="fx_url" prop="fx_url" label="首页学习业务网址">
				<a-input v-model="setting.fx_url" placeholder="请输入首页学习业务网址" />
			</a-form-model-item>

			<a-form-model-item ref="edu_url" prop="edu_url" label="个人中心学习网址">
				<a-input v-model="setting.edu_url" placeholder="请输入个人中心学习网址" />
			</a-form-model-item>


			<a-form-model-item ref="xj_url1" prop="xj_url1" label="询价电池类型疑问网址">
				<a-input v-model="setting.xj_url1" placeholder="请输入询价电池类型疑问网址" />
			</a-form-model-item>
			<a-form-model-item ref="xj_url2" prop="xj_url2" label="询价总千瓦时疑问网址">
				<a-input v-model="setting.xj_url2" placeholder="请输入询价总千瓦时疑问网址" />
			</a-form-model-item>


			<a-form-model-item ref="ref_exptime" prop="ref_exptime" label="邀请码填写超时时间(h)">
				<a-input-number v-model="setting.ref_exptime" style="width:200px;" placeholder="请输入邀请码填写超时时间" />
			</a-form-model-item>
			<a-form-model-item ref="phone" prop="phone" label="客服电话">
				<a-input v-model="setting.phone" placeholder="请输入客服电话" />
			</a-form-model-item>
			<a-form-model-item ref="email" prop="email" label="联系邮箱">
				<a-input v-model="setting.email" placeholder="请输入联系邮箱" />
			</a-form-model-item>
			<a-form-model-item ref="sms_phone" prop="sms_phone" label="公共接收短信手机号">
				<a-input v-model="setting.sms_phone" placeholder="请输入公共接收短信手机号" />
			</a-form-model-item>
			<a-divider orientation="left">
				协议政策
			</a-divider>
			<a-form-model-item ref="service" prop="service" label="服务协议" style="width:800px;">
				<AEditor v-model="setting.service" />
			</a-form-model-item>
			<a-form-model-item ref="yinsi" prop="yinsi" label="隐私政策" style="width:800px;">
				<AEditor v-model="setting.yinsi" />
			</a-form-model-item>
			<a-divider orientation="left">
				小程序配置
			</a-divider>
			<a-form-model-item ref="wx_appid" prop="wx_appid" label="小程序APPID">
				<a-input v-model="setting.wx_appid" placeholder="请输入小程序APPID" />
			</a-form-model-item>
			<a-form-model-item ref="wx_appsecret" prop="wx_appsecret" label="小程序APPSECRET">
				<a-input v-model="setting.wx_appsecret" placeholder="请输入小程序APPSECRET" />
			</a-form-model-item>
			<a-divider orientation="left">
				阿里云认证配置
			</a-divider>
			<a-form-model-item ref="ali_appcode" prop="ali_appcode" label="阿里云APPCODE">
				<a-input v-model="setting.ali_appcode" placeholder="请输入阿里云APPCODE" />
			</a-form-model-item>
			<a-divider orientation="left">
				短信配置
			</a-divider>
			<a-form-model-item ref="ali_appkey" prop="ali_appkey" label="阿里云APPKEY">
				<a-input v-model="setting.ali_appkey" placeholder="请输入阿里云APPKEY" />
			</a-form-model-item>
			<a-form-model-item ref="ali_appsecret" prop="ali_appsecret" label="阿里云APPSECRET">
				<a-input v-model="setting.ali_appsecret" placeholder="请输入阿里云APPSECRET" />
			</a-form-model-item>
			<a-form-model-item ref="sms_sign" prop="sms_sign" label="短信签名">
				<a-input v-model="setting.sms_sign" placeholder="请输入短信签名" />
			</a-form-model-item>
			<a-form-model-item ref="sms_template" prop="sms_template" label="询价通知短信模版编号">
				<a-input v-model="setting.sms_template" placeholder="请输入询价通知短信模版编号" />
			</a-form-model-item>
			<a-divider orientation="left">
				腾讯地图配置
			</a-divider>
			<a-form-model-item ref="qq_key" prop="qq_key" label="腾讯地图KEY">
				<a-input v-model="setting.qq_key" placeholder="请输入腾讯地图KEY" />
			</a-form-model-item>
			<div>
				<a-button type="primary" html-type="submit">
					修改
				</a-button>
			</div>
		</a-form-model>
	</div>
</template>

<script>
	import Func from '../../common/func'
	import AEditor from '../../components/AEditor';
	export default {
		name: 'Setting',
		components: {
			AEditor
		},
		data() {
			return {
				form: this.$form.createForm(this),
				setting: {
					ref_exptime: '48',
					phone: '',
					email: '',
					edu_url: '',
					fx_url: '',
					sms_phone: '',
					wx_appid: '',
					wx_appsecret: '',
					ali_appkey: '',
					ali_appcode: '',
					ali_appsecret: '',
					sms_sign: '',
					sms_template: '',
					qq_key: '',
					xj_url2: '',
					xj_url1: ''
				},
				rules: {}
			}
		},
		created() {
			this.get_data();
		},
		methods: {
			get_data() {
				Func.url_get({
					action: 'setting.index'
				}, (res) => {
					if (res.error === 0) {
						this.setting = res.info;
					} else {
						this.$message.error(res.info);
					}
				});
			},
			save_data(e) {
				e.preventDefault();
				this.$refs.setting_form.validate(valid => {
					if (valid) {
						Func.url_post({
							action: 'setting.save_data',
							...this.setting
						}, (res) => {
							if (res.error === 0) {
								this.$message.success(res.info);
								this.add_model = false;
								this.get_data();
							} else {
								this.$message.error(res.info);
							}
						});
					} else {
						return false;
					}
				});
			},
			goto_url(url, user_no) {
				this.$router.push({
					path: '/users/' + url,
					params: {
						user_no: user_no
					}
				});
			},
			onCreated(editor) {
				this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
			},
			onChange(editor) {
				console.log('onChange', editor.getHtml()) // onChange 时获取编辑器最新内容
			},
		}
	}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss">
</style>
