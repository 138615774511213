<template>
    <div>
        <div style="border: 1px solid #ccc;">
            <!-- 工具栏 -->
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
            />
            <!-- 编辑器 -->
            <Editor
                style="height: 400px; overflow-y: hidden;"
                :defaultConfig="editorConfig"
                v-model="value"
                @onChange="onChange"
                @onCreated="onCreated"
            />
        </div>
    </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import Func from '../common/func'
export default {
    name: 'AEditor',
    components: { Editor, Toolbar },
    props:{
      value:{
        type:String,
        default:''
      }
    },
    data() {
        return {
            editor: null,
            toolbarConfig: {
                // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
                // excludeKeys: [ /* 隐藏哪些菜单 */ ],
            },
            editorConfig: {
                placeholder: '',
                // autoFocus: false,
                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {
                }
            }
        }
    },
    created(){
      this.editorConfig.MENU_CONF['uploadImage'] = {
        // 自定义上传
        async customUpload(file, insertFn) {                   // JS 语法
          Func.url_form({
            action:'api.ed_upload',
            imgFile:file
          },(res) => {
            if(res.error===0){
              insertFn(Func.pic_url+res.url, '','');
            }else{
              this.$message.error(res.message);
            }
          });
          // file 即选中的文件
          // 自己实现上传，并得到图片 url alt href
          // 最后插入图片
          // insertFn(url, alt, href)
        }
      }
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
        },
        onChange(editor) {
          this.$emit('input',editor.getHtml());
        }
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
    },
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>